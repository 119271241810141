
<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2014-01"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search entity"
        clearable
      />
      <v-spacer />
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            small
            :disabled="!enableExport"
            :loading="!enableExport"
            color="green"
            v-on="on"
            @click="exportReport"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagination.sync="pagination"
      :total-items="totalItems"
      :rows-per-page-items="[10, 25, 50, 100]"
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.action }}
          </td>
          <td class="text-xs-left">
            {{ props.item.entity }}
          </td>
          <td
            v-if="props.item.debit"
            class="text-xs-right"
          >
            <span class="success--text">{{ props.item.amount }}</span>
          </td>
          <td
            v-else-if="props.item.amount === '0.00'"
            class="text-xs-right"
          >
            <span class="info--text">{{ props.item.amount }}</span>
          </td>
          <td
            v-else
            class="text-xs-right"
          >
            <span class="warning--text">{{ props.item.amount }}</span>
          </td>
          <td
            v-if="props.item.tag === 'topup'"
            class="text-xs-center"
          >
            <v-btn
              :href="topupReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-if="props.item.tag === 'topup2'"
            class="text-xs-center"
          >
            <v-btn
              :href="topupReceiptLink(props.item.receipt, true)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'blackRegistration' || props.item.tag === 'blackMnp'"
            class="text-xs-center"
          >
            <v-btn
              :href="blackReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'COP'"
            class="text-xs-center"
          >
            <v-btn
              :href="blackCopReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'COBP'"
            class="text-xs-center"
          >
            <v-btn
              :href="blackCobpReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'familyMainlineRegistration' || props.item.tag === 'SiswaPlusMainlineRegistration'"
            class="text-xs-center"
          >
            <v-btn
              :href="familyMainlineReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'familySublineRegistration' || props.item.tag === 'SiswaPlusSublineRegistration'"
            class="text-xs-center"
          >
            <v-btn
              :href="familySublineReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'familyBill' || props.item.tag === 'SiswaPlusBill'"
            class="text-xs-center"
          >
            <v-btn
              :href="familyBillReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'familyData' || props.item.tag === 'SiswaPlusData'"
            class="text-xs-center"
          >
            <v-btn
              :href="familyDataReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'familyMnp' || props.item.tag === 'SiswaPlusMnp'"
            class="text-xs-center"
          >
            <v-btn
              :href="familyMnpReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'MobileWifiMainlineRegistration'"
            class="text-xs-center"
          >
            <v-btn
              :href="mobileWifiReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'MobileWifiBill'"
            class="text-xs-center"
          >
            <v-btn
              :href="mobileWifiBillReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'MobileWifiMnp'"
            class="text-xs-center"
          >
            <v-btn
              :href="mobileWifiMnpReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else-if="props.item.tag === 'MobileWifiData'"
            class="text-xs-center"
          >
            <v-btn
              :href="mobileWifiDataReceiptLink(props.item.receipt)"
              target="_blank"
              fab
              small
            >
              <v-icon>mdi-receipt</v-icon>
            </v-btn>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
            -
          </td>
        </tr>
      </template>
      <template slot="no-data">
        <v-alert
          :value="true"
          :type="error ? 'error' : 'info'"
        >
          {{ noDataText }}
        </v-alert>
      </template>
      <template slot="no-result">
        <v-alert
          :value="true"
          type="info"
        >
          {{ noResultText }}
        </v-alert>
      </template>
    </v-data-table>
    <v-card>
      <v-card-text>
        <v-subheader>Legends:</v-subheader>
        <v-chip
          dark
          small
        >
          <v-avatar class="success">
            RM
          </v-avatar>
          <span class="success--text">Debit</span>
        </v-chip>
        <v-chip
          dark
          small
        >
          <v-avatar class="warning">
            RM
          </v-avatar>
          <span class="warning--text">Credit</span>
        </v-chip>
        <v-chip
          dark
          small
        >
          <v-avatar class="info">
            RM
          </v-avatar>
          <span class="info--text">Info</span>
        </v-chip>
      </v-card-text>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams, createGetUrl } from '@/rest'
import security from '@/security'
import zipcelx from 'zipcelx'

const reportTitle = 'E-recharge Records'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2014, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'E-Recharge', disabled: false, to: '/ecash_kits',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      enableExport: true,
      error: null,
      exportedReport: [],
      exportReportHeader:
        [
          {
            value: 'Date',
            type: 'string',
          },
          {
            value: 'Action',
            type: 'string',
          },
          {
            value: 'Entity',
            type: 'string',
          },
          {
            value: 'Debit',
            type: 'string',
          },
          {
            value: 'Credit',
            type: 'string',
          },
        ],
      headers: [
        {
          align: 'left',
          sortable: false,
          text: 'Date',
          value: 'entry',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Action',
          value: 'action',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Entity',
          value: 'entity',
        },
        {
          align: 'right',
          sortable: false,
          text: 'Amount',
          value: 'amount',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Receipt',
          value: null,
        },
      ],
      items: [],
      loading: false,
      maxDate: '',
      menu: false,
      monthOf: null,
      noResultText: 'No data that fits that criteria.',
      pagination: null,
      search: '',
      searchEntity: '',
      searchId: null,
      source: null,
      title: reportTitle,
      totalItems: 0,
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    exportedReport: function () {
      const config = {
        filename: security.me.dealerId() + '-' + this.monthOf,
        sheet: {
          data: [],
        },
      }
      config.sheet.data.push(this.exportReportHeader)
      this.exportedReport.forEach(item => {
        const transaction = [
          {
            value: item.entry,
            type: 'string',
          },
          {
            value: item.action,
            type: 'string',
          },
          {
            value: item.entity,
            type: 'string',
          },
          {
            value: item.debit ? item.amount : 0,
            type: 'number',
          },
          {
            value: item.debit ? 0 : item.amount,
            type: 'number',
          },
        ]
        config.sheet.data.push(transaction)
      })

      zipcelx(config)
      this.enableExport = true
    },
    monthOf: function (val) {
      this.getErechargeRecords(val)
    },
    pagination: {
      handler () {
        this.getErechargeRecords(this.monthOf)
      },
      deep: true,
    },
    search: function (val) {
      clearTimeout(this.searchId)
      this.cancelRequest()
      if (val) {
        this.searchId = setTimeout(() => {
          this.searchEntity = this.search
        }, 1000)
      } else {
        this.searchEntity = ''
      }
    },
    searchEntity: function () {
      this.getErechargeRecords(this.monthOf)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    cancelRequest: function () {
      this.loading = false
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    exportReport: function () {
      this.enableExport = false
      this.getErechargeTransactions(this.monthOf, true).then(data => {
        this.exportedReport = data.items
      })
    },
    getErechargeRecords: function (month) {
      this.getErechargeTransactions(month).then(data => {
        this.items = data.items
        this.totalItems = data.totalItems
      })
    },
    topupReceiptLink: function (id, test = false) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptTopup.php?id=' + id + (test ? '&type=1' : '')
        : createGetUrl('http://203.115.197.112/api/pdf/receiptTopup.php?id=' + id + (test ? '&type=1' : ''))
    },
    blackReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptBlackRegistration.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptBlackRegistration.php?id=' + id)
    },
    blackCopReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptBlackCop.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptBlackCop.php?id=' + id)
    },
    blackCobpReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptBlackCobp.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptBlackCobp.php?id=' + id)
    },
    familyBillReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptFamilyBill.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptFamilyBill.php?id=' + id)
    },
    familyMainlineReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptFamilyMainlineRegistration.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptFamilyMainlineRegistration.php?id=' + id)
    },
    familySublineReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptFamilySublineRegistration.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptFamilySublineRegistration.php?id=' + id)
    },
    familyDataReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptFamilyData.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptFamilyData.php?id=' + id)
    },
    familyMnpReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptFamilyMnp.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptFamilyMnp.php?id=' + id)
    },
    mobileWifiReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptMobileWifiRegistration.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptMobileWifiRegistration.php?id=' + id)
    },
    mobileWifiBillReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptMobileWifiBill.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptMobileWifiBill.php?id=' + id)
    },
    mobileWifiMnpReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptMobileWifiMnp.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptMobileWifiMnp.php?id=' + id)
    },
    mobileWifiDataReceiptLink: function (id) {
      return process.env.NODE_ENV === 'production'
        ? location.protocol + '//' + location.hostname + '/api/pdf/receiptMobileWifiData.php?id=' + id
        : createGetUrl('http://203.115.197.112/api/pdf/receiptMobileWifiData.php?id=' + id)
    },
    async getErechargeTransactions (monthOf, report = false) {
      if (!this.pagination) {
        return { items: [], totalItems: 0 }
      }
      this.loading = true
      const { page, rowsPerPage } = this.pagination
      const params = createGetParams({
        month: monthOf,
        page,
        rowsPerPage,
        search: report ? 'report' : this.searchEntity,
      })
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(params, { cancelToken: this.source.token })
        const response = await this.$rest.get('getErechargeTransactions.php', params)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        this.error = error.message
        this.loading = false
        return { items: [], totalItems: 0 }
      }
    },
  },
}
</script>
